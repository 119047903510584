import React from "react"

import styled from "styled-components"

export default function YoutubeVideo({ displayVideo }) {
  return (
    <Container
      src="https://www.youtube.com/embed/BM4xwxcb2zE?start=7?color=white&rel=0&fs=0"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={{ display: displayVideo ? "block" : "none" }}
    ></Container>
  )
}

const Container = styled.iframe`
  display: none;

  margin-top: 40px;

  height: 562px;
  width: 100%;
  max-width: 1000px;

  padding: 0 20px;

  @media (max-width: 375px) {
    height: 301px;
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
  }
`
